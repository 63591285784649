<template>
  <div class="post-warp mt20 mb20" style="min-height: 500px;">
    <div v-if="info">

      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
          <h2 class="line font-rem-16">{{info[lang + 'title']}}</h2>
          <span class="font-rem-10 mt20" style="color: #8A8A8A; display: inline-block">{{info.create_time}}</span>

          <div class="post-content" v-loading="loading" v-html="info[lang + 'content']"></div>

          <share :title="info.title" :image="info.thumbnail ? info.thumbnail : ''"></share>
        </el-col>

        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="ad-images" v-for="(list, i) in ad" :key="`ad` + i" @click="goto(list.url)">
            <el-image fit="cover" lazy :src="list.image"></el-image>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-empty v-else :description="$t('tips.error_post')">
      <el-button @click="$router.back()" size="mini" type="primary">{{$t('button.go_back')}}</el-button>
    </el-empty>
  </div>
</template>

<script>
import {getPosts, getOption} from '@/api/os/admin';
import share from '@/components/Share';

export default {
  components: {
    share
  },
  data() {
    return {
      optionName: 'page-ad',
      id: this.$route.params.id,
      loading: true,
      info: {},
      ad: []
    }
  },
  created() {
    this.get()
    this.getOptions()
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    get() {
      this.loading = true
      getPosts({id: this.id}).then(res => {
        this.loading = false
        this.info = res.data.data
      })
    },
    getOptions() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.ad = res.data.data.posts || []
        }
      })
    },
    goto(url) {
      if(url) {
        window.location = url
      }
    },
  }
}
</script>

<style lang="less">
.post-warp{
  max-width: 1200px;
  margin: 30px auto;
}
.post-content{
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #B0B0B0;
  img{
    max-width: 100%;
  }
}
.ad-images{
  margin-bottom: 20px;
}
</style>